import React, { useState } from 'react';
import './App.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactUs } from './ContactUs';

const App: React.FC = () => {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  if (!recaptchaKey) {
    console.error("RECAPTCHA_SITE_KEY is not defined!");
  }

  return (
    <div className={`App ${isDarkMode ? 'darkmode' : ''}`}>
      <div className='wrapper'>
        <div className="container">
          <header id="home">
            <nav className="navbar">
              <h2 className='name'>Abdelrahim Akroh</h2>
              <ul className="navbar__ul">
                <li className='link'><a href="#portfolio">Projects</a></li>
                <li className='link'><a href="#skills">Skills</a></li>
                <li className='link'><a href="#contact">Contact</a></li>
                <li>
                  <button 
                    className='darkmode' 
                    onClick={toggleDarkMode} 
                    style={{
                      color: isDarkMode ? 'orange' : 'black', 
                      backgroundColor: isDarkMode ? 'rgb(43, 43, 43)' : 'white'
                    }}
                  >
                    <i className={`fas fa-${isDarkMode ? 'sun' : 'moon'}`}></i>
                  </button>
                </li>
              </ul>
            </nav>
            <div className="header__container">
              <img src="Mon-avatar.png" alt="profile" />
              <div className="header__text__container">
                <p className="header__text">Bonjour! Je suis Abdelrahim, un développeur front-end et back-end avec une expertise dans diverses technologies et frameworks. Explorez mon portfolio pour voir mes projets et compétences.</p>
              </div>
            </div>
          </header>
        </div>

        <main className="portfolio" id='portfolio'>
          <h2 className='portfolio__header'>Projects</h2>
          <div className="portfolio__container">
            <article className='card'>
              <img src='https://cdn-icons-png.flaticon.com/512/3437/3437297.png' alt="page" />
              <div className="text-container">
                <h2>Connection Page</h2>
                <p>Développement d'une page de connexion robuste utilisant PHP et Symfony pour un projet interne.</p>
              </div>
            </article>

            <article className='card'>
              <img src='https://cdn-icons-png.flaticon.com/512/993/993725.png' alt="page" />
              <div className="text-container">
                <h2>Data Collection App</h2>
                <p>Application de collecte de données utilisant React.js et Docker, assurant une gestion efficace des données.</p>
              </div>
            </article>

            <article className='card'>
              <img src='https://cdn-icons-png.flaticon.com/512/1055/1055645.png' alt="app" />
              <div className="text-container">
                <h2>Search App</h2>
                <p>Développement d'une application de recherche performante avec PHP et React.js, intégrant des API REST.</p>
              </div>
            </article>

            <article className='card'>
              <img src='https://cdn-icons-png.flaticon.com/512/4257/4257674.png' alt="app" />
              <div className="text-container">
                <h2>Favorite Spots</h2>
                <p>Création d'une application de gestion des lieux favoris utilisant Vue.js et Docker, offrant une expérience utilisateur fluide.</p>
              </div>
            </article>

            <article className='card'>
              <img src='https://cdn-icons-png.flaticon.com/512/5956/5956494.png' alt="gallery" />
              <div className="text-container">
                <h2>Project Gallery</h2>
                <p>Développement d'une galerie de projets pour visualiser les travaux passés et actuels, utilisant Symfony et React.js.</p>
              </div>
            </article>

            <article className='card'>
              <img src='https://cdn-icons-png.flaticon.com/512/891/891175.png' alt="Calculator" />
              <div className="text-container">
                <h2>Calculator</h2>
                <p>Création d'une calculatrice web avec JavaScript et CSS, intégrant des fonctionnalités avancées pour les calculs quotidiens.</p>
              </div>
            </article>
          </div>

          <section className='skills' id='skills'>
            <h2>Skills</h2>
            <article className="skills-container">
              <div className="skills__item" data-aos='fade-up'>
                <img src="https://cdn-icons-png.flaticon.com/512/1199/1199124.png" alt="JS" />
                <p>JavaScript</p>
              </div>

              <div className="skills__item" data-aos='fade-up'>
                <img src="https://cdn-icons-png.flaticon.com/512/1051/1051277.png" alt="HTML5" />
                <p>HTML5</p>
              </div>

              <div className="skills__item" data-aos='fade-up'>
                <img src="https://cdn-icons-png.flaticon.com/512/732/732190.png" alt="CSS" />
                <p>CSS3</p>
              </div>

              <div className="skills__item" data-aos='fade-up'>
                <img src="https://cdn-icons-png.flaticon.com/512/5968/5968705.png" alt="figma" />
                <p>Figma</p>
              </div>

              <div className="skills__item" data-aos='fade-up'>
                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/drupal-icon.png" width={"60px"} alt="Drupal" />
                <p>Drupal</p>
              </div>


              <div className="skills__item" data-aos='fade-up'>
                <img src="https://cdn-icons-png.flaticon.com/512/919/919830.png" alt="PHP" />
                <p>PHP</p>
              </div>

              <div className="skills__item" data-aos='fade-up'>
                <img src="https://static-00.iconduck.com/assets.00/file-type-light-symfony-icon-256x256-we3833w0.png" alt="Symfony" />
                <p>Symfony</p>
              </div>

              <div className="skills__item" data-aos='fade-up'>
                <img src="https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png" alt="React" />
                <p>React.js</p>
              </div>
            </article>
          </section>

          <footer>
            <div className="wrapper">
              <ContactUs />
            </div>
            <div className='footer__nav-hr'>
              <nav className="footer__nav">
                <h3>Abdel</h3>
                <ul className='footer__nav-links'>
                  <li><a href="#home">Home</a></li>
                  <li><a href="#portfolio">Projects</a></li>
                </ul>
                <p className='to-top'><a href="#top"><strong>BACK TO TOP</strong></a></p>
              </nav>
            </div>
          </footer>
        </main>

        <footer>
          <p>&copy; 2024 Akh Web. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
