import React, { useRef, useState, FormEvent } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID!,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
          form.current,
          process.env.REACT_APP_EMAILJS_USER_ID!
        )
        .then(
          () => {
            setSuccessMessage('✅ Email envoyé avec succès !');
            setErrorMessage(null); // Clear any previous error message
            form.current?.reset();
          },
          (error) => {
            setErrorMessage('❌ Échec de l\'envoi de l\'email. Veuillez réessayer plus tard.');
            console.log('Échec de l\'envoi...', error.text);
          },
        );
    }
  };

  return (
    <div className="contact-section" id="contact">
      <div className="footer__text-container">
        <h2>Contact</h2>
        <p>
          N'hésitez pas à me contacter pour toute collaboration ou projet. Je suis disponible par téléphone ou email.
        </p>
      </div>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="field">
          <label htmlFor="formEmail">Email</label>
          <input type="email" name="formEmail" id="formEmail" required />
        </div>

        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" required />
        </div>
        <input type="submit" id="button" value="Envoyer Email" />
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </form>
    </div>
  );
};
